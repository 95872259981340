import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { AuthContainer } from "../../common/AuthContainer"
import { assets } from "../../utils"
import { CustomButton } from "../../components/CustomButton"
import { useAuthContext } from "../../contexts/AuthContext"
import { SyncPopup } from "./syncpopup"
import {WoocommercePopUp} from "./woocommercepopup"
import { useUserProfileContext } from "../../contexts/UserProfileContext"

export const LinkShopify = () => {
  const nav = useNavigate()
  const { isLoading: logoutLoading, userLogout } = useAuthContext()
  const { isLoading, getLoginUserProfile ,  userProfile, wordpressConnect} = useUserProfileContext()
  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)

  const [woocommerceClicked, setWoocommerceClicked] = useState(false)

  const [showWoocommerce, setShowWoocommerce] = useState(false)
  const [woocommerceUrl, setWoocommerceUrl] = useState("")
  const [woocommerceName, setWoocommerceName] = useState("")
  const onHideWoocommerce = () => setShowWoocommerce(false)
  const onClickHandleWoocommerce = () => {
    setShowWoocommerce(true)
  }

  const onClickHandle = () => {
    setShow(true)
    const redirect_url = "https://www.google.com/"
    window.open(redirect_url, "_blank").focus()
  }

  const onClickSync = () => {
    getLoginUserProfile().then(response => {
      const { user_store } = response
      if (user_store?.id) {
        setTimeout(() => nav("/subscription"), 300)
      }
      // if (!user_store?.id) {
      //   setTimeout(() => nav("/link-shopify-app"), 300)
      // }
    })
  }

  const onClickWoocommerce = () => {
    wordpressConnect( woocommerceUrl,woocommerceName).then(response => {
      setWoocommerceClicked(true)
      const { store_url,id } = response
      const redirect_url = "https://www.smokesource.app    /connect/wordpress-connect/?url=" + store_url+"&id="+id
      window.open(redirect_url, "_blank").focus()
    })
  }

  useEffect(() => {
    getLoginUserProfile()
  }, [])

  const onRefresh = () => {
    window.location.reload()
  }
  return (
    <>
      <AuthContainer
        title="Sync Account With"
        // subTitle="What platform do you want to Sync with?"
        className="login"
      >
        <div className="shopify-logo-container mb-5">
          <p className="text-mute" style={{ opacity: "0.7" }}>
            What platform do you want to Sync with?
          </p>
          <p className="text-mute">
            Please select the platform of the store you want to link.
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="shopify-logo" style={shopifyLogoDiv}  onClick={onClickHandle}>
              <img src={assets.ShopifyImg} style={{width:'100%'}}/>
            </div>
            <div className="shopify-logo" style={shopifyLogoDiv}  onClick={onClickHandleWoocommerce}>
              <img src={assets.WoocommerceImg} style={{width:'100%'}}/>
            </div>
          </div>
          
        </div>
        <CustomButton
          label="Logout"
          type="button"
          variant="outline-primary"
          loading={logoutLoading}
          onClick={() => userLogout().then(() => nav("/login"))}
        ></CustomButton>
      </AuthContainer>
      <SyncPopup
        show={show}
        onHide={onHide}
        isLoading={isLoading}
        onClick={onClickSync}
      />
      <WoocommercePopUp
        show={showWoocommerce}
        onHide={onHideWoocommerce}
        isLoading={isLoading}
        onClick={onClickWoocommerce}
        setWoocommerceUrl={setWoocommerceUrl}
        woocommerceUrl={woocommerceUrl}
        setWoocommerceName={setWoocommerceName}
        woocommerceName={woocommerceName}
        clicked={woocommerceClicked}
        onRefresh={onRefresh}
      />
    </>
  )
}

const shopifyLogoDiv = {
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  width: "49%",
  padding: "2rem",
  borderRadius: "0.5rem",
  cursor: "pointer"
}
