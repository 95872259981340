import { post, get, put, del, patch } from "./http-methods"

export const API = {
  login: payload => post("/api/v1/login/", payload),
  shopifyLogin: config => get("/api/v1/shopify-authenticate/", config),
  bigcommerceLogin: config => post("/connect/bigcommerce-login/", config), 
  signup: payload => post("/api/v1/signup/", payload),
  shopifySignup: config => get("/api/v1/shopify-finalize/", config),
  logout: () => post("/rest-auth/logout/"),
  forgetPassword: payload => post("/rest-auth/password/reset/", payload),
  resetPasswordConfirm: payload =>
    post("/rest-auth/password/reset/confirm/", payload),
  passwordChange: payload => post("/rest-auth/password/change/", payload),
  getLoginProfile: () => get("/api/v1/profile/login-profile/"),
  getTimezones: () => get("/api/v1/get-timezones/"),
  updateUserProfile: (id, payload) => patch(`/api/v1/profile/${id}/`, payload),
  wordpressConnect: (payload) => post(`/connect/wordpress-add/`, payload),
  bigcommerceConnect: (payload) => post(`/connect/bigcommerce-add/`, payload),
  getPaymentProfiles: config =>
    get("/payment/api/v1/payment-profiles/", config),
  addPaymentProfile: payload =>
    post("/payment/api/v1/payment-profiles/", payload),
  addPaymentSubscription: payload =>
    post("/subscriptions/api/v1/subscription-other/", payload),
  deletePaymentProfile: id => del(`/payment/api/v1/payment-profiles/${id}/`),
  getSubscriptions: () => get("/subscriptions/api/v1/subscription-plans/"),
  addSubscriptionRequest: payload =>
    post("/subscriptions/api/v1/subscription-request/", payload),
  getCurrentSubscription: () => get("/subscriptions/api/v1/user-subscription/"),
  getAmountToSubscribe: config =>
    post("/subscriptions/api/v1/subscription-request/", config),
  makePaymentDefault: id =>
    post(`/payment/api/v1/payment-profiles/${id}/make-as-default/`),
  autoPay: (id, payload) => patch(`/api/v1/profile/${id}/`, payload),
  unsubscribePlan: id =>
    post(`/subscriptions/api/v1/user-subscription/${id}/unsubscribe/`),
  sendFeedback: payload => post("/api/v1/send-feedback/", payload),
  //Browse Products
  getBrowseProducts: config => get("/browse-products/api/v1/", config),
  getBrowseProduct: id => get(`/browse-products/api/v1/${id}/`),
  getBrands: config => get("/browse-products/api/v1/get-vendors/", config),
  getProductTypes: config =>
    get("/browse-products/api/v1/get-product-types/", config),
  addToMyProducts: payload => post("/my-products/api/v1/", payload),
  deleteMyProduct: id => del(`/my-products/api/v1/${id}/`),
  bulkDelete: config => del("/my-products/api/v1/bulk-delete/", config),
  updateSellingPrice: (id, payload) =>
    put(`/my-products/api/v1/variants/${id}/`, payload),
  checkCostPrice: id => get(`/browse-products/api/v1/${id}/cost-price/`),
  resetPriceUpdates: () => post("/my-products/api/v1/reset-price-updates/"),
  //My Products
  addVariant: payload => post("/my-products/api/v1/variants/", payload),
  removeVariant: id => del(`/my-products/api/v1/variants/${id}/`),
  //Dashboard
  getSummary: () => get("/my-products/api/v1/summary/"),
  getOrderAnalytics: () => get("/orders/api/v1/order-analytics/"),
  //Privay Policy and Terms
  getPrivacyPolicy: () => get("/modules/privacy-policy/"),
  getTerms: () => get("/modules/terms-and-conditions/"),
  getAppUrlToInstall: config => get("/api/v1/get-app-install-url/", config),
  //Orders
  getOrders: config => get("/orders/api/v1/", config),
  getOrder: id => get(`/orders/api/v1/${id}/`),
  createManualOrder: payload => post("/orders/api/v1/manual-order/", payload),
  orderAddressChange: payload => post("/orders/api/v1/order-address/", payload),
  raiseOrderRequest: payload =>
    post("/orders/api/v1/order-pay-request/", payload),
  getOrderNote: () => get("/orders/api/v1/order-note/"),
  addOrderNote: payload => post("/orders/api/v1/order-note/", payload),
  editOrderNote: (id, payload) =>
    patch(`/orders/api/v1/order-note/${id}/`, payload),
  deleteOrderNote: id => del(`/orders/api/v1/order-note/${id}/`),
  syncOrders: () => post("/orders/api/v1/sync-orders/"),
  getOrderSummary: () => get("/my-products/api/v1/summary/"),
  downloadInvoice: (id, config) =>
    get(`orders/api/v1/${id}/download-invoice/`, config),
  //admin
  getUserList: config => get("/dashboard/api/v1/users/", config),
  //shipping
  getCarriers: () => get("/orders/api/v1/carriers/"),
  getRates: (orderId, config) =>
    get(`/orders/api/v1/${orderId}/get-rates/`, config),
  getConfig: () => get("/api/v1/get-config-values/"),
  updateStock: payload => post("/my-products/api/v1/update-stock/", payload),
}
